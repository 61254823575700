<template>
  <div :id="`SELECT_IMPORT_TYPE_FORM`" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="sm" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" modal-class="event-type-modal-anchor"
      @hidden="cancelModal"
      scrollable
    >

      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <div class="d-block">
        <b-form-group>
          <b-form-radio v-if="showPredecessor" v-model="type" value="predecessor">{{$t('task_predecessor.field.predecessor')}}</b-form-radio>
          <b-form-radio v-if="!showPredecessor" v-model="type" value="booking">{{$t('booking.title')}}</b-form-radio>
          <b-form-radio v-if="!showPredecessor" v-model="type" value="activity">{{$t('activity.title')}}</b-form-radio>
          <b-form-radio v-model="type" value="tasks">{{$t('task.title')}}</b-form-radio>
          <b-form-radio v-if="!hideNonWork" v-model="type" value="nonwork">{{$t('vacation.title')}}</b-form-radio>
        </b-form-group>
      </div>
      
      <template v-slot:modal-footer="{ }">
        <b-button size="sm" variant="success" @click="handleOkBtnClicked">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancelModal">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);

import { cloneDeep } from 'lodash';
import { convertDate } from '@/helpers';
import { projectService, stageService } from '@/services';

export default {
  name: 'SelectImportType',
  components: {

  },
  props: {
    title:     { type: String,   default: null },
    show:      { type: Boolean, required: true },
    hideNonWork: { type: Boolean, default: false },
    showPredecessor: { type: Boolean, default: false }
  },
  data() {
    return {
      modalShow: false,
      type: 'booking'
    }
  },
  computed: {
    labelTitle() {
      return this.$t('staff.select_import');
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.modalShow = newValue;
        this.type = this.showPredecessor ? 'tasks' : 'booking';
      }
    }
  },
  methods: {
    cancelModal() {
      this.$emit('cancel');
      this.$emit('update:show', false)
    },
    handleOkBtnClicked() {
      this.$emit('ok', this.type)
      this.$emit('update:show', false)
    }
    //End of methods
  }
}
</script>

<style lang="scss" scoped>

.cost-block {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.cost-left {
  margin-right: 15px;
}

.cost-right {
  margin-left: 15px;
}

.image-preview-container {
  width: 100%;
  border: 1px var(--form-control-border) solid;
  padding: 5px;
  min-height: 36px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.image-preview {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 300px;
}

.actual-cost-alert {
  color: var(--status-alt-red);
  position: absolute;
  right: 11px;
  top: 8px;
}
.work-effort-alert {
  color: var(--status-alt-red);
}
.colorRed {
  color: var(--status-alt-red) !important;
}

.rebate-total {
  text-align: right;
  float: right;
  display: inline-block;
}

@media (min-width: 1200px) {
  .remove-padding {
    padding-left: 0;
  }
}
</style>

<style lang="scss">
a.list-group-item {
  border: none !important;
  color: var(--form-control);
  background-color: var(--form-control-bg);
}

.actual-duration-form-control-fix {
  flex: 1 1 auto;
  width: 1%;

  &:not(:last-child) {
    input.form-control {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

@media screen and (max-width: 991px) {
  .identifier-margin {
    margin-left: 15px;
  }
}

@media screen and (max-width: 1200px) {
  .color-margin {
    margin-left: 15px;
  }
}

.stage-label-offset label {
  position: relative;
  top: 3px;
}
</style>