var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: `SELECT_IMPORT_TYPE_FORM` },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "sm",
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "event-type-modal-anchor",
            scrollable: "",
          },
          on: { hidden: _vm.cancelModal },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function ({}) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.handleOkBtnClicked },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: { click: _vm.cancelModal },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block" },
            [
              _c(
                "b-form-group",
                [
                  _vm.showPredecessor
                    ? _c(
                        "b-form-radio",
                        {
                          attrs: { value: "predecessor" },
                          model: {
                            value: _vm.type,
                            callback: function ($$v) {
                              _vm.type = $$v
                            },
                            expression: "type",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("task_predecessor.field.predecessor"))
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.showPredecessor
                    ? _c(
                        "b-form-radio",
                        {
                          attrs: { value: "booking" },
                          model: {
                            value: _vm.type,
                            callback: function ($$v) {
                              _vm.type = $$v
                            },
                            expression: "type",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("booking.title")))]
                      )
                    : _vm._e(),
                  !_vm.showPredecessor
                    ? _c(
                        "b-form-radio",
                        {
                          attrs: { value: "activity" },
                          model: {
                            value: _vm.type,
                            callback: function ($$v) {
                              _vm.type = $$v
                            },
                            expression: "type",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("activity.title")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-form-radio",
                    {
                      attrs: { value: "tasks" },
                      model: {
                        value: _vm.type,
                        callback: function ($$v) {
                          _vm.type = $$v
                        },
                        expression: "type",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("task.title")))]
                  ),
                  !_vm.hideNonWork
                    ? _c(
                        "b-form-radio",
                        {
                          attrs: { value: "nonwork" },
                          model: {
                            value: _vm.type,
                            callback: function ($$v) {
                              _vm.type = $$v
                            },
                            expression: "type",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("vacation.title")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }