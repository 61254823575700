var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.$t("skill.title_level_edit"),
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "skill-level-modal shadow",
            scrollable: "",
          },
          on: { hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                return [
                  _vm.canApplyAll
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.applyToAll,
                            callback: function ($$v) {
                              _vm.applyToAll = $$v
                            },
                            expression: "applyToAll",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.state.errorShow,
                callback: function ($$v) {
                  _vm.$set(_vm.state, "errorShow", $$v)
                },
                expression: "state.errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.$t("error.attention_required")) + " "),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container pl-0" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("skill.field.skill"),
                            "label-for": "skillName",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: { required: true },
                                    expression: "{ required: true }",
                                  },
                                ],
                                staticClass: "form-label",
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.$t("skill.select_skill"),
                                  readonly: "",
                                  "data-vv-as": _vm.$t("skill.field.skill"),
                                  "data-vv-name": "skillName",
                                  "data-vv-delay": "500",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.canList() ? _vm.skillSelectorOpen : ""
                                  },
                                },
                                model: {
                                  value: _vm.skillName,
                                  callback: function ($$v) {
                                    _vm.skillName = $$v
                                  },
                                  expression: "skillName",
                                },
                              }),
                              _vm.canList()
                                ? _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          on: { click: _vm.skillSelectorOpen },
                                        },
                                        [_c("span", [_vm._v("Select")])]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-form-invalid-feedback",
                            {
                              staticClass: "alert-danger form-field-alert",
                              class: { "d-block": _vm.showSkillNameError },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "circle-exclamation"] },
                              }),
                              _vm._v(
                                "  " +
                                  _vm._s(_vm.errors.first("skillName")) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.customFieldMap["name"] != null
                    ? _vm._l(
                        _vm.customFieldMap["name"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "name" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                    },
                                    model: {
                                      value: _vm.customData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customData,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "customData[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.customFieldMap["default"] != null
                    ? _vm._l(
                        _vm.customFieldMap["default"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                    },
                                    model: {
                                      value: _vm.customData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customData,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "customData[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("skill.field.level"),
                            "label-for": "skillLevel",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: { required: true },
                                    expression: "{ required: true }",
                                  },
                                ],
                                attrs: {
                                  options: _vm.levelOpts,
                                  "data-vv-as": _vm.$t("skill.field.level"),
                                  "data-vv-name": "skillLevel",
                                  "data-vv-delay": "500",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "first",
                                    fn: function () {
                                      return [
                                        _c(
                                          "b-form-select-option",
                                          {
                                            attrs: {
                                              value: null,
                                              disabled: "",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("skill.select_level")
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.skillLevel,
                                  callback: function ($$v) {
                                    _vm.skillLevel = $$v
                                  },
                                  expression: "skillLevel",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-invalid-feedback",
                            {
                              staticClass: "alert-danger form-field-alert",
                              class: { "d-block": _vm.showSkillLevelError },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "circle-exclamation"] },
                              }),
                              _vm._v(
                                "  " +
                                  _vm._s(_vm.errors.first("skillLevel")) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.customFieldMap["level"] != null
                    ? _vm._l(
                        _vm.customFieldMap["level"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "level" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                    },
                                    model: {
                                      value: _vm.customData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customData,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "customData[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.state.skillSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.skillSelectorShow,
              entityService: _vm.skillUtil,
              entity: "SKILL",
              preselected: _vm.id,
              nonAdmin: "",
              singleSelection: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "skillSelectorShow", $event)
              },
              ok: _vm.skillSelectorOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }